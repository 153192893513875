<template>
    <div class="post mx-auto">
        <p class="mb-0">{{ t(`enterTitle`) }}</p>
        <h4><input type="text" v-model="postInfo.title" class="w-100"></h4>
        <p class="mt-3 mb-1">{{ t('beginingDate') }}</p>
        <div class="d-flex">
            <b-form-datepicker v-model="startDate" class="col-8" />
            <b-form-timepicker v-model="startTime" locale="ru" class="col-4" />
        </div>
        <p class="mt-3 mb-1">{{ t('endDate') }}</p>
        <div class="d-flex">
            <b-form-datepicker v-model="endDate" class="col-8" />
            <b-form-timepicker v-model="endTime" locale="ru" class="col-4" />
        </div>
        <file-drop class="my-2" @imageChange="imageChange"/>
        <p class="mb-0">{{ t(`enterDescription`) }}</p>
        <h5><input type="text" v-model="postInfo.description" class="w-100 mb-5"></h5>
        <vue-editor
            v-model="postInfo.content" 
            use-custom-image-handler 
            @image-added="handleImageAdded" 
            @image-removed="removeImage"
        />
        <div class="bottom">
            <div class="profile-pic" :style="author.avatar ? {'background-image': `url(${author.avatar})`} : {}" />
            <p>{{ author.name }}</p>
            <p class="time">{{ parseTime(Date.now()) }}</p>
            <button class="main submit-btn" @click.prevent="submit">{{ t('publicate') }}</button>
        </div>
    </div>
</template>

<script>
import FileDrop from '../../components/FileDrop.vue';
import parseTime from '../../utils/parseTime';
import { VueEditor } from "vue2-editor";

export default {
    name: 'CreatePost',
    components: { 
        FileDrop,
        VueEditor,
    },
    data() {
        return {
            liked: false,
            startDate: '',
            startTime: '09:00:00',
            endDate: '',
            endTime: '18:00:00',
            postInfo : {},
        }
    },
    mounted() {
        const date = new Date()
        this.startDate = `${ date.getFullYear() }-${ date.getMonth() + 1 }-${ date.getDate() }`
        const end = new Date(Date.now() + 3 * 24 * 60 * 60 * 1000)
        this.endDate = `${ end.getFullYear() }-${ end.getMonth() + 1 }-${ end.getDate() }`
    },
    methods: {
        removeImage(url) {
            this.axios.post('images/remove', {image: url})
        },
        handleImageAdded: function(file, Editor, cursorLocation, resetUploader) {
            var formData = new FormData();
            formData.append("image", file);

            this.axios({
                url: "/images",
                method: "POST",
                data: formData
            })
            .then(result => {
                let url = result.data.url; // Get url from response
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            })
            .catch(err => {
                err = err.response?.data?.message || err.message
                if(err.includes('The thumbnail failed to upload.')) {
                    this.error(this.t('imageTooBig'))
                } else
                    this.error(err);
            });
        },
        parseTime: (time) => parseTime(time),
        imageChange(file) {
            this.postInfo.thumbnail = file
        },
        async submit() {
            if(this.postInfo.content == 'undefined' || !this.postInfo.content) {
                this.error(this.t('fillWithText'))
                return;
            }
            const formData = new FormData();
            formData.append('title', this.postInfo.title)
            formData.append('content', this.postInfo.content)
            formData.append('description', this.postInfo.description)
            if(this.postInfo.thumbnail) {
                formData.append('thumbnail', this.postInfo.thumbnail)
            }
            formData.append('company_id', this.$store.state.usersCompany.id)
            formData.append('start_at', this.startDate + ' ' + this.startTime)
            formData.append('start_at', this.startDate + ' ' + this.startTime)
            formData.append('end_at', this.endDate + ' ' + this.endTime)
            try {
                await this.axios.post('events', formData)
                this.alert(this.t('eventPremoderating'))
                this.$router.push('/')
            } catch (e) {
                const data = e.response?.data?.message || []
                if(data.includes('The category field is required.')) {
                    this.error(this.t('tagsNeeded'))
                } else if(data.includes("The thumbnail must be a file of type: jpeg, jpg, png.")) {
                    this.error(this.t('addThumbnail'))
                } else if(data.includes('The thumbnail failed to upload.')) {
                    this.error(this.t('imageTooBig'))
                }
            }
        }
    },
    computed: {
        author() {  
            if(this) {
                this.$forceUpdate();
                if(this.$store.state.userHasCompany) 
                    return this.$store.state.usersCompany
                else
                    return this.$store.state.userInfo
            } else return {}
        }
    },
}
</script>

<style scoped lang="scss">
    input {
        border-radius: 3px !important;
        padding-left: 0 !important;
        border: 2px solid rgb(255, 255, 255);
    }
    input:hover {
        border: 2px solid #7fb92821;
    }
    .post {
        text-align: start;
        background: #FFFFFF;
        box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
        backdrop-filter: blur(32px);
        border-radius: 15px;
        padding: 16px;
        max-width: 790px;
        margin: 20px 0;
    }
    .select {
        overflow: hidden;
    }
    .top, .bottom {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        p {
            font-weight: bold;
            margin-left: 10px;
            margin-bottom: 0;
        }
        .time {
            margin-left: 3%;
            font-size: 14px;
            opacity: 0.4;
        }
    }
    .bottom {
        margin-top: 10px;
        border-top: 2px solid rgba(128, 128, 128, 0.158);
        padding-top: 10px;
        padding-bottom: 20px;

        .submit-btn {
            margin-left: auto;
        }
    }
    .thumbnail {
        background-color: grey;
        cursor: pointer;
        border-radius: 10px;
        width: 100%;
        min-height: 300px;
        background-size: cover;
        background-position: center;
    }
    .content {
        max-width: 100%;
        word-wrap: break-word;
        margin: 10px 5px;
        margin-bottom: 20px;
    }
    .actions-row {
        display: flex;
        .comments, .views {
            opacity: 0.5;
        }
        span {
            font-weight: bold;
            margin-left: 4px;
            color: var(--itpark)
        }
    }
    .actions-row > * {
        cursor: pointer;
        margin: 10px;
    }
    @media (max-width: 540px) {
        
    }
</style>